<div class="container screen-size">
    <div class="row h-100">
        <div class="col h-100">
            <div class="d-flex flex-column justify-content-center align-items-center text-center h-100">
                <img src="./assets/me.jpg" alt="It's a me, mario" class="mb-3" style="object-fit: cover; width: 150px">
                <h1 class="font-italic font-weight-bold" >Kevin Johar</h1>
                <p class="mb-0">Currently building cool internal tools @ BMO.</p>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col">
            <div class="d-flex flex-column">
                <div>
                    <a [routerLink]="['']" class="btn btn-link">home</a>
                    <a [routerLink]="['projects']" class="btn btn-link">projects</a>
                    <a [href]="resumeLink" target="_blank" class="btn btn-link">my resume</a>
                </div>
                <div>
                    <a href="mailto:kevin.s.johar@gmail.com" class="btn btn-link" style="color: black">
                        <i class="fas fa-envelope fa-1x mx-1"></i>
                    </a>

                    <a href="https://github.com/kevin-johar" target="_blank" class="btn btn-link" style="color: black">
                        <i class="fab fa-github fa-1x mx-1"></i>
                    </a>

                    <a href="https://www.linkedin.com/in/kevin-johar/" target="_blank" class="btn btn-link" style="color: black">
                        <i class="fab fa-linkedin-in fa-1x mx-1"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

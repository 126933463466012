<div class="container vh-100">
    <div class="row">
        <div class="col-12 text-center">
            <h2 class="mb-3">my projects</h2>
            <div class="row">
                <div *ngFor="let project of projects" class="col-12 col-md-4">
                    <app-project [project]="project"></app-project>
                </div>
            </div>
        </div>
        <div class="col-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
